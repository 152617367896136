@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker__day {
  color: var(--text);
  font-weight: 400;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1rem;
  color: var(--light-gray);
  height: 20px;
  padding: unset;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container > input {
  font-size: 14px;
}
