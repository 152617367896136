.sk-circle {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;

  & .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  & .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #4650dd;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  & .sk-circle2 {
    transform: rotate(30deg);
  }
  & .sk-circle3 {
    transform: rotate(60deg);
  }
  & .sk-circle4 {
    transform: rotate(90deg);
  }
  & .sk-circle5 {
    transform: rotate(120deg);
  }
  & .sk-circle6 {
    transform: rotate(150deg);
  }
  & .sk-circle7 {
    transform: rotate(180deg);
  }
  & .sk-circle8 {
    transform: rotate(210deg);
  }
  & .sk-circle9 {
    transform: rotate(240deg);
  }
  & .sk-circle10 {
    transform: rotate(270deg);
  }
  & .sk-circle11 {
    transform: rotate(300deg);
  }
  & .sk-circle12 {
    transform: rotate(330deg);
  }
  & .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  & .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  & .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  & .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  & .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  & .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  & .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  & .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  & .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  & .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  & .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  &--absolute {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
