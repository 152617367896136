@import "./react-datapicker__input-text";

@supports (font-variation-settings: normal) {
  :root { font-family: 'DM Sans', sans-serif; }
}

option {
  color: black;
}

.chakra-select__control {
  height: 40px !important;
  max-height: unset !important;
  min-height: unset !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
  color: inherit !important;
  text-transform: none !important;
}

.chakra-select--small__control {
  height: 24px !important;
  max-height: unset !important;
  min-height: unset !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
  color: inherit !important;
  text-transform: none !important;
  box-sizing: content-box !important;
}

.chakra-select--small__menu {
  margin-top: 4px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
  color: inherit !important;
  text-transform: none !important;
}

.chakra-select--small__menu-notice {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
  color: inherit !important;
  text-transform: none !important;
}

.chakra-select--small__value-container {
  padding-left: 8px !important;
}

.chakra-select--small__value-container {
  padding-top: 0 !important;
}

@import "./modules/Loader";
@import "./MiniCalendar.scss";

span.size-20 {
  font-size: 20px;
  font-variation-settings: 'OPSZ' 20;
}
span.size-24 {
  font-size: 24px;
  font-variation-settings: 'OPSZ' 24;
}
span.size-40 {
  font-size: 40px;
  font-variation-settings: 'OPSZ' 40;
}
span.size-48 {
  font-size: 48px;
  font-variation-settings: 'OPSZ' 48;
}
span.fill {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
}
td[data-is-numeric=true], th[data-is-numeric=true] {
  font-variant-numeric: lining-nums tabular-nums slashed-zero;
  font-feature-settings: 'cv04' on, 'ss01' on;
  text-align: start !important;
}

